.clearfix-css:after {
  content: "";
  display: block;
  clear: both;
}
.centered {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.centered-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  text-align: center;
}
.inline-block {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.fix-inline {
  word-spacing: -0.36em;
  display: table;
  width: 100%;
}
.fix-inline-normal {
  word-spacing: normal;
}
.material {
  font-family: "Material Design Icons";
  font-weight: normal;
  font-style: normal;
}
.fa_icon {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}
.blured {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  -o-filter: blur(2px);
  filter: blur(2px);
}
.blured-none {
  -webkit-filter: blur(0);
  -moz-filter: blur(0);
  -ms-filter: blur(0);
  -o-filter: blur(0);
  filter: blur(0);
}
.shadow-material {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -ms-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.shadow-material-min {
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -ms-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -o-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.shadow-material-med {
  -webkit-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  -ms-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  -o-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.shadow-material-large {
  -webkit-box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
  -ms-box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
  -o-box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
}
.shadow-material-big {
  -webkit-box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
  -ms-box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
  -o-box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}
/******************** box-sizing ********************/
/***************** background-size ********************/
/******************** Закругление углов ********************/
/**
*.block {
*    .border-radius();
*    .border-radius(10px);
*    .border-radius(5px 5px 0px 0px);
*    .border-radius(0px 0px 5px 5px);
*}
**/
/******************** Прозрачность ********************/
/**
*.block {
*    .opacity(50);
*}
**/
/******************** вертикальный градиент ********************/
/******************** горизонтальный градиент ********************/
/******************** поверхностный градиент ********************/
/**
*.block {
*    .gradient-vertical(red,blue); создаст вертикальный градиент от красного к синему цвету
*    .gradient-horizontal(red,blue); создаст горизонтальный градиент от красного к синему цвету
*}
*.block {
*     background: red; основной цвет, на который накладывается градиент
*     .gradient(left, 0.2) этот тип градиента создает быструю маску градиента на основной цвет, может использовать значения top, left, bottom, right, второй параметр - прозрачность маски
*}
*.button {
*     background: red; основной цвет, на который накладывается градиент
*     .quick-gradient(top, 0.2); накладываем маску
*     .transitions(all 0.5s ease); устанавливаем время и эффект перехода, вместо all можно поставить background
*     &:hover {
*         background: blue; /* в какой цвет будет плавно переходить фон
*     }
*}
**/
/******************** Тень ********************/
/**
*.block {
*    .box-shadow(inset 0px 0px 5px 0px #000);
*}
**/
/******************** Переходы ********************/
/**
*.block {
*    height: 50px;
*    .transitions(all 2s ease);
*    &:hover {
*        height: 20px;
*    }
*}
**/
/******************** Трансформация элементов ********************/
/**
*.block {
*    .transform(90deg, 1, 0deg, 0px); поворот на 90 градусов
*}
**/
/******************** Анимация CSS3 ********************/
/**
*.block {
*    height: 50px; width: 50px;
*    background: red;
*    .animation(mymove, 2s, infinite, linear);
*}
*@-webkit-keyframes mymove {
*    0%   {background: red; left:0px; top:0px;}
*    25%  {background: yellow; left:200px; top:0px;}
*    50%  {background: blue; left:200px; top:200px;}
*    75%  {background: green; left:0px; top:200px;}
*    100% {background: red; left:0px; top:0px;}
*}
*@keyframes mymove {
*    0%   {background: red; left:0px; top:0px;}
*    25%  {background: yellow; left:200px; top:0px;}
*    50%  {background: blue; left:200px; top:200px;}
*    75%  {background: green; left:0px; top:200px;}
*    100% {background: red; left:0px; top:0px;}
*}
**/
/******************** Подключение шрифтов ********************/
/**
*.include-custom-font(icomoon); //opensans etc.
**/
/******************************************************************************/
*,
*:after,
*:before {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  outline: none !important;
}
*::selection {
  background-color: rgba(255, 171, 22, 0.7);
}
*::-moz-selection {
  background-color: rgba(255, 171, 22, 0.7);
}
html.open {
  -webkit-transform: translateX(240px);
  -moz-transform: translateX(240px);
  -ms-transform: translateX(240px);
  -o-transform: translateX(240px);
  transform: translateX(240px);
}
html,
body {
  height: 100%;
  background-color: #191919;
}
html.page-light,
body.page-light {
  background-color: #ffffff;
}
body {
  position: relative;
  display: block;
  font-size: 15px;
  line-height: 21px;
  min-width: 320px;
  color: #ffffff;
  font-family: 'Clear Sans';
  font-weight: 300;
  margin: 0 auto;
  padding-left: 240px;
}
body.wifi-page {
  padding-left: 0;
}
body.page-light {
  color: #191919;
  font-weight: 400;
}
.wrapper {
  height: auto !important;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 320px;
  position: relative;
  display: block;
  margin: 0 auto;
  background-color: #1D1D1D;
}
.page-light .wrapper {
  background-color: #ffffff;
}
.wrapper_content {
  position: relative;
  width: 100%;
  max-width: 970px;
  margin: 0 auto;
  padding: 0 40px;
}
.wrapper_content.main {
  max-width: 1170px;
  padding: 0 20px;
}
.wrapper_content.main.gal {
  padding: 0 20px;
}
.wrapper_content.view {
  padding-top: 50px;
}
.wrapper_content.nopad {
  padding: 0;
}
.wrapper_content.room {
  max-width: 970px;
}
.content {
  position: relative;
  padding: 0 0 80px;
  z-index: 1;
}
a,
a:focus {
  display: inline-block;
  zoom: 1;
  *display: inline;
  text-decoration: none;
  color: #ffffff;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
a span,
a:focus span {
  -webkit-transition: border-bottom-color 0.2s ease-in-out;
  -moz-transition: border-bottom-color 0.2s ease-in-out;
  -o-transition: border-bottom-color 0.2s ease-in-out;
  transition: border-bottom-color 0.2s ease-in-out;
  border-bottom: 1px solid rgba(255, 171, 22, 0.4);
}
a:hover,
a:focus:hover {
  color: #ff484e;
  text-decoration: none;
}
a:hover span,
a:focus:hover span {
  border-bottom-color: rgba(255, 72, 78, 0.4);
}
a.orange_light_color,
a:focus.orange_light_color {
  color: #ffab16;
}
a.orange_light_color:hover,
a:focus.orange_light_color:hover {
  color: #ff484e;
}
a.min,
a:focus.min {
  font-size: 13px;
  line-height: 19px;
}
a.dotted span,
a:focus.dotted span {
  border-bottom-style: dotted;
}
.page-light a,
.page-light a:focus {
  color: #191919;
}
.page-light a span,
.page-light a:focus span {
  border-bottom-color: rgba(255, 171, 22, 0.6);
}
.page-light a:hover,
.page-light a:focus:hover {
  color: #ff484e;
}
.page-light a:hover span,
.page-light a:focus:hover span {
  border-bottom-color: rgba(255, 72, 78, 0.3);
}
ul,
ol,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 0;
  font-weight: 700;
  font-family: Candara, Calibri, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
}
h1,
.h1 {
  font-size: 56px;
  line-height: 62px;
  margin-bottom: 25px;
}
h2,
.h2 {
  font-size: 36px;
  line-height: 42px;
  margin-bottom: 20px;
}
h3,
.h3 {
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 20px;
}
h4,
.h4 {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 10px;
}
h5,
.h5 {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 10px;
}
h6,
.h6 {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}
p,
div {
  margin: 0;
}
label {
  display: block;
  font-size: 13px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
  margin-bottom: 5px;
}
.page-light label {
  color: rgba(29, 29, 29, 0.5);
  font-weight: 400;
}
blockquote {
  padding: 0 25px;
  font-size: 18px;
  margin: 20px auto;
  line-height: 24px;
  border-left: 3px solid #ff5343;
  font-style: italic;
}
iframe {
  display: block;
  border: none;
}
iframe.tour {
  margin-bottom: 0;
}
iframe.tour_tab {
  margin-bottom: 0;
  height: 570px;
}
.wow {
  visibility: hidden;
}
.bg_img {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}
.bg_img.contain {
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.bg_fade {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: rgba(29, 29, 29, 0.8);
}
.bg_fade_green {
  background-color: rgba(73, 202, 139, 0.9);
}
.page-light .bg_fade {
  background-color: rgba(255, 255, 255, 0.7);
}
.bg_fade_menu {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: rgba(29, 29, 29, 0.8);
  z-index: 90;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  visibility: hidden;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}
.bg_fade_menu.open {
  visibility: visible;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.top_img_page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 280px;
  margin: 0 -20px;
  overflow: hidden;
}
.top_img_page_img {
  position: relative;
  height: 100%;
  width: 100%;
}
.top_img_page_gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: -moz-linear-gradient(top, rgba(29, 29, 29, 0.5) 0%, #1D1D1D 100%);
  background: -webkit-linear-gradient(top, rgba(29, 29, 29, 0.5) 0%, #1D1D1D 100%);
  background: linear-gradient(to bottom, rgba(29, 29, 29, 0.5) 0%, #1D1D1D 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#801d1d1d', endColorstr='#1d1d1d', GradientType=0);
}
.page-light .top_img_page .top_img_page_gradient {
  background: -moz-linear-gradient(top, rgba(29, 29, 29, 0.1) 0%, #ffffff 100%);
  background: -webkit-linear-gradient(top, rgba(29, 29, 29, 0.1) 0%, #ffffff 100%);
  background: linear-gradient(to bottom, rgba(29, 29, 29, 0.1) 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#801d1d1d', endColorstr='#1d1d1d', GradientType=0);
}
.line.gray {
  border-color: rgba(255, 255, 255, 0.1);
}
.page-light .line.gray {
  border-color: rgba(29, 29, 29, 0.1);
}
.table_block {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
}
.table_block.va_top .table_cell {
  vertical-align: top;
}
.table_cell {
  display: table-cell;
  vertical-align: middle;
}
.prim {
  font-size: 13px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
}
.page-light .prim {
  color: rgba(29, 29, 29, 0.7);
  font-weight: 400;
}
.text_bigger,
.text_bigger p,
.text_bigger ul,
.text_bigger ol,
.text_bigger li,
.text_bigger div {
  font-size: 18px;
  line-height: 28px;
}
.text_fade {
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=85);
  -moz-opacity: 0.85;
  -khtml-opacity: 0.85;
  opacity: 0.85;
}
.text_fade_60 {
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=60);
  -moz-opacity: 0.6;
  -khtml-opacity: 0.6;
  opacity: 0.6;
}
.text_italic {
  font-style: italic;
}
.text_red {
  color: #ff5343;
}
.text ul,
.text ol {
  margin-left: 20px;
}
.text ul,
.text ol,
.text li {
  list-style-type: initial;
}
.phone {
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
}
.phone.big {
  font-size: 24px;
  line-height: 24px;
  margin-top: 10px;
}
.title_gray {
  color: rgba(255, 255, 255, 0.4);
  letter-spacing: 4px;
  font-weight: 300;
}
.title_line {
  overflow: hidden;
}
.title_line:before,
.title_line:after {
  content: "";
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: baseline;
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
}
.title_line:before {
  margin-left: -100%;
  left: -20px;
}
.title_line:after {
  margin-right: -100%;
  right: -20px;
}
.title_line span {
  position: relative;
  display: block;
  padding-left: 10px;
  background-color: #1D1D1D;
  z-index: 1;
}
.title_line span.right {
  float: right;
  font-size: 15px;
  line-height: inherit;
  font-family: 'Clear Sans';
  font-weight: 300;
}
.page-light .title_line:before,
.page-light .title_line:after {
  background-color: rgba(29, 29, 29, 0.1);
}
.page-light .title_line span {
  background-color: #ffffff;
}
.title_page {
  text-align: center;
  padding-top: 50px;
  margin-bottom: 25px;
}
.title_page.view {
  padding-top: 0;
  font-size: 48px;
  line-height: 54px;
  margin-bottom: 40px;
}
.iconed {
  position: relative;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
  padding-left: 65px;
  text-align: left;
}
.iconed img,
.iconed .mdi,
.iconed .fa {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  max-width: 46px;
  max-height: 50px;
}
.iconed_min {
  padding-left: 20px;
}
.iconed_min .mdi,
.iconed_min .fa {
  top: 5px;
  max-width: 20px;
  max-height: 20px;
  color: #ffab16;
}
.iconed.right {
  padding-left: 0;
}
.iconed.arrow-right:after {
  content: "\F054";
  font-family: "Material Design Icons";
  font-weight: normal;
  font-style: normal;
  vertical-align: middle;
  color: #ffab16;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin-left: 5px;
}
.iconed.arrow-right:hover:after {
  color: #ff484e;
}
.action_img {
  position: relative;
  display: block;
  max-width: 90px;
  margin-bottom: 20px;
  z-index: 1;
}
.action_img.min {
  max-width: 60px;
  margin-bottom: 5px;
}
.action_img.inline {
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: middle;
  margin-right: 15px;
  margin-bottom: 0;
}
.page_date {
  margin-bottom: 10px;
}
.page_img {
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=90);
  -moz-opacity: 0.9;
  -khtml-opacity: 0.9;
  opacity: 0.9;
  margin-bottom: 30px;
}
.page_img img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 460px;
}
.page_descrip {
  max-width: 770px;
  margin: 0 auto 30px;
}
.page_content {
  margin-bottom: 60px;
}
.page_content p,
.page_content ul,
.page_content ol,
.page_content blockquote,
.page_content h1,
.page_content h2,
.page_content h3,
.page_content h4,
.page_content h5,
.page_content h6 {
  max-width: 770px;
  margin: 0 auto;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=90);
  -moz-opacity: 0.9;
  -khtml-opacity: 0.9;
  opacity: 0.9;
  margin-bottom: 10px;
}
.page_content blockquote {
  margin-top: 20px;
  margin-bottom: 20px;
}
.page_content ul li {
  position: relative;
  margin-bottom: 20px;
}
.page_content ul li:last-child {
  margin-bottom: 0;
}
.page_content ul li:after {
  content: "";
  position: absolute;
  top: 11px;
  left: -20px;
  display: block;
  height: 2px;
  width: 8px;
  background-color: #ffab16;
}
.page_bg {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 390px;
  margin-bottom: 30px;
}
.page_bg.reviews_bg {
  min-height: 230px;
  margin-bottom: -50px;
}
.page_bg.reviews_bg .page_bg_img {
  height: 230px;
}
.page_bg .bg_img {
  position: relative;
  width: 100%;
  height: 100%;
}
.page_bg_text {
  margin-bottom: 30px;
}
.page_bg_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 390px;
  overflow: hidden;
}
.page-light .page_bg_img .page_gradient {
  background: -moz-linear-gradient(top, rgba(29, 29, 29, 0.1) 0%, #ffffff 100%);
  background: -webkit-linear-gradient(top, rgba(29, 29, 29, 0.1) 0%, #ffffff 100%);
  background: linear-gradient(to bottom, rgba(29, 29, 29, 0.1) 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#801d1d1d', endColorstr='#1d1d1d', GradientType=0);
}
.breadcrumb_block {
  margin: 0 0 25px;
}
.questions {
  margin: 60px 0;
}
.questions_title {
  margin-bottom: 10px;
}
.questions .phone.min {
  font-size: 15px;
  line-height: inherit;
}
.how_move {
  text-align: center;
}
.how_move .icons_block {
  margin-bottom: 15px;
}
.how_move .icons_block .icon {
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
  max-width: 30px;
  max-height: 30px;
  margin-right: 20px;
}
.how_move .icons_block .icon:last-child {
  margin-right: 0;
}
.how_move a {
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.ov_hidden {
  overflow: hidden;
}
.row {
  margin-right: -20px;
  margin-left: -20px;
}
.container-fluid {
  padding-right: 20px;
  padding-left: 20px;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  padding-right: 20px;
  padding-left: 20px;
}
.page_gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: -moz-linear-gradient(top, rgba(29, 29, 29, 0.5) 0%, #1d1d1d 100%);
  background: -webkit-linear-gradient(top, rgba(29, 29, 29, 0.5) 0%, #1d1d1d 100%);
  background: linear-gradient(to bottom, rgba(29, 29, 29, 0.5) 0%, #1d1d1d 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#801d1d1d', endColorstr='#1d1d1d', GradientType=0);
}
.bottom_gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px;
  z-index: 0;
  background: -moz-linear-gradient(top, rgba(29, 29, 29, 0) 0%, #1D1D1D 100%);
  background: -webkit-linear-gradient(top, rgba(29, 29, 29, 0) 0%, #1D1D1D 100%);
  background: linear-gradient(to bottom, rgba(29, 29, 29, 0) 0%, #1D1D1D 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#001d1d1d', endColorstr='#001d1d1d', GradientType=0);
}
.top_grdient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  z-index: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(29, 29, 29, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(29, 29, 29, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(29, 29, 29, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#801d1d1d', endColorstr='#00000000', GradientType=0);
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.alert {
  position: relative;
  padding: 20px;
}
.alert-info {
  background-color: #1D1D1D;
  color: #ffffff;
  border: 1px solid rgba(255, 171, 22, 0.6);
}
.alert .close {
  position: absolute;
  top: 10px;
  right: 10px;
  text-shadow: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=60);
  -moz-opacity: 0.6;
  -khtml-opacity: 0.6;
  opacity: 0.6;
  color: #ffffff;
  width: 24px;
  height: 24px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.alert .close:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
/** initial setup **/
.nano {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.nano > .nano-content {
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.nano > .nano-content:focus {
  outline: thin dotted;
}
.nano > .nano-content::-webkit-scrollbar {
  display: none;
}
.nano > .nano-pane {
  background: transparent;
  position: absolute;
  width: 10px;
  right: 0;
  top: 0;
  bottom: 0;
  /* Target only IE7 and IE8 with this hack */
  visibility: hidden\9;
  opacity: .01;
  -webkit-transition: .2s;
  -moz-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.nano > .nano-pane > .nano-slider {
  background: transparent;
  position: relative;
  margin: 0 1px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.nano:hover > .nano-pane,
.nano-pane.active,
.nano-pane.flashed {
  /* Target only IE7 and IE8 with this hack */
  visibility: visible\9;
  opacity: 0.99;
}
.has-scrollbar > .nano-content::-webkit-scrollbar {
  display: block;
}
.nav_main {
  position: fixed;
  top: 0;
  left: 0;
  width: 240px;
  height: 100%;
  overflow: visible!important;
  background-color: #1D1D1D;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  z-index: 100;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  -webkit-transform: translateX(-240px);
  -moz-transform: translateX(-240px);
  -ms-transform: translateX(-240px);
  -o-transform: translateX(-240px);
  transform: translateX(-240px);
}
.nav_main.open {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
}
.nav_main_logo {
  padding: 25px 40px;
}
.nav_main_logo img {
  display: block;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
.nav_main_logo a {
  display: block;
}
.nav_main_logo.mob {
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  padding: 12px 0 12px 45px;
  z-index: 1;
  width: 100%;
  background-color: #1D1D1D;
}
.nav_main_logo.mob .close_menu {
  position: absolute;
  left: 10px;
  top: 9px;
  padding: 10px;
  color: #ffab16;
  font-size: 24px;
  line-height: 24px;
}
.nav_main_logo.mob .mob_logo {
  position: absolute;
  right: 8px;
  top: 12px;
  height: 35px;
  visibility: visible;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.nav_main_logo.mob .mob_logo.visability {
  visibility: hidden;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}
.nav_main_menu li a {
  position: relative;
  display: block;
  padding: 10px 25px;
}
.nav_main_menu li a,
.nav_main_menu li a:focus {
  color: #ffffff;
}
.nav_main_menu li a:hover {
  background-color: #262626;
}
.nav_main_menu li.active a,
.nav_main_menu li.active a:focus,
.nav_main_menu li.active a:hover {
  background-color: #2D2D2D;
  color: #ffffff;
  cursor: default;
}
.nav_main_menu li.active a span,
.nav_main_menu li.active a:focus span,
.nav_main_menu li.active a:hover span {
  border-bottom-color: rgba(255, 171, 22, 0.4);
}
.nav_main_menu li.active a:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ffab16;
  height: 100%;
  width: 5px;
  z-index: 0;
}
.nav_main_menu li.active:first-child a,
.nav_main_menu li.active:last-child a,
.nav_main_menu li.active:first-child a:hover,
.nav_main_menu li.active:last-child a:hover {
  font-style: italic;
  color: #ffab16;
}
.nav_main_menu li:first-child a,
.nav_main_menu li:last-child a {
  color: #ffab16;
}
.nav_main_menu li:first-child a:hover,
.nav_main_menu li:last-child a:hover {
  color: #ff5343;
}
.nav_main_menu li:last-child a {
  font-style: italic;
}
.nav_main_contacts {
  padding: 0 25px 25px;
}
.nav_main_contacts .address a,
.nav_main_contacts .links a,
.nav_main_contacts .address a:focus,
.nav_main_contacts .links a:focus {
  color: #ffffff;
}
.nav_main_contacts .address a:hover,
.nav_main_contacts .links a:hover {
  color: #ff484e;
}
.nav_main_contacts .address {
  margin-bottom: 25px;
}
.nav_main_contacts .phone_block {
  margin-bottom: 25px;
}
.nav_main_contacts .phone_block .prim {
  margin-bottom: 5px;
}
.nav_main_contacts .social {
  margin-bottom: 25px;
}
.nav_main_contacts .social a,
.nav_main_contacts .social a:focus {
  display: inline-block;
  zoom: 1;
  *display: inline;
  margin-right: 8px;
  font-size: 18px;
  line-height: 18px;
  color: #ffab16;
}
.nav_main_contacts .social a:hover,
.nav_main_contacts .social a:focus:hover {
  color: #ff484e;
}
.nav_main_contacts .booking_img img {
  width: 15px;
  margin-top: -4px;
}
.page_tabs {
  display: block;
  margin-bottom: 50px;
  word-spacing: -0.36em;
  display: table;
  width: 100%;
}
.page_tabs:after {
  content: "";
  display: block;
  clear: both;
}
.page_tabs li {
  word-spacing: normal;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
  margin-right: 30px;
}
.page_tabs li:last-child {
  margin-right: 0;
}
.page_tabs li a {
  position: relative;
  display: block;
  padding: 0 0 16px;
}
.page_tabs li.active a {
  cursor: default;
  color: #ffffff;
}
.page_tabs li.active a span {
  border-bottom: none;
}
.page_tabs li.active a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #ffab16;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.page-light .page_tabs li.active a {
  color: #1D1D1D;
  font-weight: bold;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 320px;
  padding: 0 20px;
  z-index: 59;
  height: 60px;
  overflow: hidden;
  background-color: #1D1D1D;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.header.tr {
  background-color: transparent;
}
.header_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 12px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}
.header .menu_toggle {
  position: absolute;
  left: -12px;
  top: 7px;
  display: block;
  color: #ffab16;
  font-size: 24px;
  line-height: 24px;
  padding: 12px;
}
.header_logo {
  display: inline-block;
  zoom: 1;
  *display: inline;
  height: 100%;
}
.header_logo.visability img {
  visibility: visible;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.header_logo img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  visibility: hidden;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.empty {
  height: 405px;
}
.footer {
  position: relative;
  margin-top: -405px;
  min-height: 405px;
  min-width: 320px;
  z-index: 0;
}
.footer .wrapper {
  padding-top: 60px;
  background: #191919 url(/images/bg_footer.png) 0 0 repeat;
}
.footer .title_gray {
  text-transform: uppercase;
}
.footer .top_grdient {
  z-index: 1;
}
.footer_pre_btns {
  margin-bottom: 50px;
}
.footer_pre_btns .left {
  float: left;
}
.footer_pre_btns .right {
  float: right;
}
.footer_pre_btns .btn {
  cursor: pointer;
  margin-right: 10px;
}
.footer_pre_btns .btn:last-child {
  margin-right: 0;
}
.footer_pre_nav_trip .icon {
  display: block;
  width: 80px;
  margin-bottom: 15px;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=40);
  -moz-opacity: 0.4;
  -khtml-opacity: 0.4;
  opacity: 0.4;
}
.footer_pre_nav_menu_grid {
  word-spacing: -0.36em;
  display: table;
  width: 100%;
}
.footer_pre_nav_menu_grid_item {
  word-spacing: normal;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
  width: 25%;
  padding-right: 25px;
}
.footer_pre_nav_menu li {
  display: block;
  margin-bottom: 20px;
  overflow: hidden;
}
.footer_pre_nav_menu li a,
.footer_pre_nav_menu li a:focus {
  color: #ffffff;
}
.footer_block .left {
  float: left;
}
.footer_block .right {
  float: right;
}
.footer_block .prim,
.footer_block .link {
  margin-right: 20px;
}
.footer_block .prim {
  color: rgba(255, 255, 255, 0.5);
}
.footer_block a,
.footer_block a:focus {
  color: #ffffff;
}
.social-likes__icon {
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  position: absolute;
  top: 5px;
  left: 13px;
  font-size: 18px;
}
.social-likes__icon_facebook:before {
  content: "\f09a";
  color: #3765a3;
}
.social-likes__icon_twitter:before {
  content: "\f099";
  color: #5ea9dd;
}
.social-likes__icon_vkontakte:before {
  content: "\f189";
  color: #597da3;
}
.social-likes__counter {
  padding-left: 7px;
}
.btn {
  position: relative;
  display: inline-block;
  font-size: 15px;
  line-height: 16px;
  padding: 10px 25px 12px;
  font-weight: 500;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -khtml-border-radius: 3px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.btn span {
  border-bottom: none !important;
}
.btn,
.btn:focus,
.page-light .btn,
.page-light .btn:focus {
  color: #ffffff;
}
.btn_iconed_right {
  padding-right: 40px;
}
.btn_iconed_right .mdi {
  position: absolute;
  font-size: 24px;
  right: 9px;
  top: 7px;
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  -o-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: transform 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
.btn_iconed_right.collapsed .mdi {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.btn_min,
.btn_min:focus {
  padding: 6px 20px 8px;
  font-size: 15px;
  line-height: 16px;
}
.btn.soc {
  padding: 6px 10px 7px;
  font-weight: 300;
}
.btn.big {
  padding: 16px 30px;
}
.btn_dark,
.btn_dark:focus {
  border: solid 1px rgba(255, 171, 22, 0.6);
  background-color: #1D1D1D;
  color: #ffab16;
}
.btn_dark:hover,
.btn_dark:focus:hover {
  background-color: #ffab16;
  color: #1D1D1D !important;
}
.page-light .btn_dark,
.page-light .btn_dark:focus {
  background-color: #ffab16;
  color: #1D1D1D;
}
.page-light .btn_dark:hover,
.page-light .btn_dark:focus:hover {
  background-color: #ffab16;
  color: #ffab16;
}
.btn_orange,
.btn_orange:focus {
  border: solid 1px #ffab16;
  color: #1D1D1D;
  background-color: #ffab16;
}
.btn_orange:hover,
.btn_orange:focus:hover {
  background-color: #1D1D1D;
  color: #ffab16;
}
.btn_orange,
.btn_orange:focus,
.page-light .btn_orange,
.page-light .btn_orange:focus {
  color: #1D1D1D;
}
.btn_orange:hover,
.btn_orange:focus:hover,
.page-light .btn_orange:hover,
.page-light .btn_orange:focus:hover {
  background-color: #ffab16;
  color: #1D1D1D;
}
.btn_dark-white,
.btn_dark-white:focus {
  border: solid 1px rgba(255, 171, 22, 0.6);
  color: #ffffff;
  background-color: #000000;
}
.btn_dark-white:hover,
.btn_dark-white:focus:hover {
  color: #ff484e;
  border-color: #ff484e;
}
.btn_dark-white:hover,
.btn_dark-white:focus:hover,
.page-light .btn_dark-white:hover,
.page-light .btn_dark-white:focus:hover {
  color: #1D1D1D;
  background-color: #ffab16;
  border-color: #ffab16;
}
.btn_gray {
  background-color: #2D2D2D;
  font-weight: normal;
}
.btn_iconed {
  position: relative;
  padding-left: 40px !important;
}
.btn_iconed:hover {
  color: #ffffff;
  background-color: rgba(255, 171, 22, 0.3);
}
.btn_iconed.fb:hover {
  background-color: rgba(55, 101, 163, 0.3);
}
.btn_iconed.vk:hover {
  background-color: rgba(89, 125, 163, 0.3);
}
.btn_iconed.tw:hover {
  background-color: rgba(94, 169, 221, 0.3);
}
.btn_iconed .fa,
.btn_iconed .mdi,
.btn_iconed img {
  position: absolute;
  top: 5px;
  left: 13px;
  font-size: 18px;
}
.btn_iconed .fa-map-marker,
.btn_iconed .mdi-map-marker,
.btn_iconed img-map-marker,
.btn_iconed .fa-info-circle,
.btn_iconed .mdi-info-circle,
.btn_iconed img-info-circle {
  color: #ffbd49;
}
.btn.block {
  display: block;
  width: 100%;
}
.change-thm-box {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 500;
}
.btn_beatysalon {
  width: 250px;
}
.form-control,
select {
  position: relative;
  background-color: #e5e5e5;
  color: #1D1D1D;
  font-size: 15px;
  line-height: 38px;
  height: 40px;
  padding: 10px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -khtml-border-radius: 3px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.form-control:hover,
select:hover,
.form-control:focus,
select:focus {
  background-color: #ffffff;
}
.form-control:focus,
select:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: transparent;
}
.form-control::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
  font-style: italic;
  font-size: 13px;
}
.form-control::-moz-placeholder,
select::-moz-placeholder {
  color: rgba(29, 29, 29, 0.5);
  font-style: italic;
  font-size: 13px;
}
.form-control:-moz-placeholder,
select:-moz-placeholder {
  color: rgba(29, 29, 29, 0.5);
  font-style: italic;
  font-size: 13px;
}
.form-control:-ms-input-placeholder,
select:-ms-input-placeholder {
  color: rgba(29, 29, 29, 0.5);
  font-style: italic;
  font-size: 13px;
}
.page-light .form-control,
.page-light select {
  background-color: #ffffff;
}
.page-light .form-control:hover,
.page-light select:hover {
  border-color: rgba(29, 29, 29, 0.5);
}
.page-light .form-control:focus,
.page-light select:focus {
  border-color: #1D1D1D;
}
textarea.form-control {
  height: 120px;
  max-width: 100%;
  padding-top: 5px;
}
select {
  width: 100px !important;
  max-width: 100%;
}
.form-inline .form-group {
  margin-right: 7px;
  vertical-align: top;
}
.form-inline .form-group:last-child {
  margin-right: 0;
}
.form-group:last-child,
.form_cols:last-child {
  margin-bottom: 0;
}
.form-group .with_icon,
.form_cols .with_icon {
  position: relative;
}
.form-group .with_icon .form-control,
.form_cols .with_icon .form-control {
  padding-right: 40px;
}
.form-group .with_icon .icon,
.form_cols .with_icon .icon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  display: block;
  z-index: 1;
}
.form-group .left,
.form_cols .left,
.form-group .right,
.form_cols .right {
  width: 47%;
}
.form-group .left,
.form_cols .left {
  float: left;
  margin-right: 3%;
}
.form-group .right,
.form_cols .right {
  float: right;
  margin-left: 3%;
}
.form-group.block input,
.form_cols.block input {
  display: block;
  width: 100%;
}
.form-group.error .form-control,
.form_cols.error .form-control {
  border-color: #ff5343;
}
.form-group.error .help-inline,
.form_cols.error .help-inline {
  color: #ff5343;
}
.date_inp {
  max-width: 170px;
}
.star_block {
  margin-bottom: 30px;
}
.star_block_title {
  margin-bottom: 10px;
}
.star_block .rating-container .star {
  cursor: pointer;
}
input[type='file'] {
  display: none;
}
.date {
  width: 170px !important;
}
.help-inline {
  display: block;
  font-size: 13px;
  line-height: 19px;
  text-align: left;
  margin-top: 5px;
}
.feedback_form {
  max-width: 570px;
}
.feedback_form.no_center {
  margin: 0;
}
.feedback_form.form-centered {
  margin-right: auto;
  margin-left: auto;
}
.feedback_form .form-group.left,
.feedback_form .form-group.right {
  width: 49%;
  margin-bottom: 20px;
}
.feedback_form .form-group.left .prim,
.feedback_form .form-group.right .prim {
  margin-top: 5px;
}
.feedback_form .form-group.left {
  float: left;
  margin-right: 2%;
}
.feedback_form .form-group.right {
  float: right;
}
.feedback_form_btn {
  margin-right: 20px;
}
.feedback_form .files_block .btn_block .btn,
.feedback_form .files_block .btn_block .prim {
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: middle;
}
.feedback_form .files_block .btn_block .btn {
  margin-right: 25px;
}
.feedback_form .files_block_list {
  margin-top: 20px;
}
.feedback_form .files_block_list_one {
  padding-right: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
  font-size: 15px;
  font-weight: 300;
}
.feedback_form .files_block_list_one:last-child {
  margin-right: 0;
}
.feedback_form .files_block_list_one .file_name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}
.feedback_form .files_block_list_one .del {
  position: absolute;
  right: 0;
  top: 0;
  color: #ffab16;
  cursor: pointer;
}
.form_subscibe {
  text-align: center;
  max-width: 460px;
  margin: 0 auto;
}
.form_subscibe .group_btn {
  margin-top: 10px;
}
.form_subscibe .form-group {
  vertical-align: top;
  margin-right: 5px;
}
.form_subscibe .form-group:last-child {
  margin-bottom: 0;
}
.form_subscibe .form-group .email {
  width: 100%;
  max-width: 290px;
}
.form_subscibe .form-group.with_btn {
  position: relative;
  padding-right: 150px;
}
.form_subscibe .form-group.with_btn .btn {
  position: absolute;
  top: 0;
  right: 0;
}
.ui-datepicker {
  padding: 0;
  border: none;
  background-color: #1D1D1D;
  border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0;
  -khtml-border-radius: 3px 3px 0 0;
  color: #ffffff;
  font-family: 'Clear Sans';
}
.ui-datepicker th {
  font-weight: 300;
}
.ui-datepicker-header {
  padding: 0;
  background: #ffab16;
  border: none;
  border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0;
  -khtml-border-radius: 3px 3px 0 0;
}
.ui-datepicker-header .ui-icon {
  background-image: none;
  display: none !important;
}
.ui-datepicker table.ui-datepicker-calendar {
  margin: 0;
}
.ui-datepicker .ui-corner-all {
  cursor: pointer;
  color: #1D1D1D;
}
.ui-datepicker .ui-corner-all.ui-state-disabled {
  color: #1D1D1D !important;
}
.ui-datepicker .ui-corner-all.ui-state-hover {
  background-color: transparent;
  border: none;
  color: #ff484e;
}
.ui-datepicker-prev {
  top: 7px !important;
}
.ui-datepicker-prev-hover {
  left: 2px !important;
  top: 7px !important;
}
.ui-datepicker-prev:after {
  content: "\F141";
  font-family: "Material Design Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
}
.ui-datepicker-next {
  top: 7px !important;
}
.ui-datepicker-next-hover {
  right: 2px !important;
  top: 7px !important;
}
.ui-datepicker-next:after {
  content: "\F142";
  font-family: "Material Design Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
}
.ui-datepicker .ui-state-default,
.ui-datepicker .ui-widget-content .ui-state-default,
.ui-datepicker .ui-widget-header .ui-state-default {
  border: 1px solid transparent;
  background: #262626;
  font-weight: normal;
  color: #ffffff;
}
.ui-datepicker .ui-state-highlight,
.ui-datepicker .ui-widget-content .ui-state-highlight,
.ui-datepicker .ui-widget-header .ui-state-highlight {
  border: 1px solid #ffab16;
  background: #262626;
  color: #ffffff;
}
.ui-datepicker .ui-state-active,
.ui-datepicker .ui-widget-content .ui-state-active,
.ui-datepicker .ui-widget-header .ui-state-active {
  border: 1px solid transparent;
  background: #ffab16;
  font-weight: 700;
  color: #1D1D1D;
}
.ui-datepicker .ui-state-hover {
  border: 1px solid #ff484e;
  background: #262626;
  font-weight: normal;
  color: #ff484e;
}
input[type="checkbox"],
input[type="radio"] {
  display: none;
}
.checkbox_input {
  top: -3px;
  vertical-align: top;
  width: 20px;
  height: 20px;
  margin: 0;
}
.checkbox_input + label {
  cursor: pointer;
}
.checkbox_input:not(checked) {
  position: absolute;
  opacity: 0;
}
.checkbox_input:not(checked) + label {
  position: relative;
  padding-left: 25px;
  font-weight: 300;
  font-size: 13px;
}
.checkbox_input:not(checked) + label:hover:before {
  border-color: #ff484e;
}
.checkbox_input:not(checked) + label:hover:after {
  color: #ff484e;
}
.checkbox_input:not(checked) + label:before,
.checkbox_input:not(checked) + label:after {
  position: absolute;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -khtml-border-radius: 2px;
  border: 1px solid transparent;
  font-size: 15px;
  line-height: 15px;
}
.checkbox_input:not(checked) + label:before {
  content: '';
  left: 0px;
  top: 0;
  width: 18px;
  height: 18px;
  border-color: #ffab16;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.checkbox_input:not(checked) + label:after {
  content: "\f00c";
  left: 2px;
  top: -2px;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  width: 18px;
  height: 18px;
  color: #ffab16;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-size: 17px;
}
.checkbox_input:checked + label:after {
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.chosen-container {
  width: 100px !important;
  max-width: 100%;
}
.chosen-container-single .chosen-single {
  position: relative;
  background-color: #e5e5e5;
  color: #1D1D1D;
  font-size: 15px;
  line-height: 38px;
  height: auto;
  padding: 0 10px;
  border: 1px solid #e5e5e5;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -khtml-border-radius: 3px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-image: none;
}
.chosen-container-single .chosen-single div {
  width: 30px;
}
.chosen-container-single .chosen-single div b {
  background: none !important;
}
.chosen-container-single .chosen-single div b:after {
  content: "\F140";
  font-family: "Material Design Icons";
  font-weight: normal;
  font-style: normal;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  text-align: center;
  font-size: 22px;
  color: rgba(29, 29, 29, 0.4);
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.chosen-container-single .chosen-single span {
  border: none;
}
.chosen-container-single .chosen-single:hover {
  background-color: #ffffff;
}
.chosen-container-active.chosen-with-drop .chosen-single {
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: #e5e5e5;
  background-color: #ffffff;
}
.chosen-container-active.chosen-with-drop .chosen-single div b:after {
  -webkit-transform: rotate(180deg) scale(1) skew(0deg) translate(0px);
  -moz-transform: rotate(180deg) scale(1) skew(0deg) translate(0px);
  -o-transform: rotate(180deg) scale(1) skew(0deg) translate(0px);
  -ms-transform: rotate(180deg) scale(1) skew(0deg) translate(0px);
  transform: rotate(180deg) scale(1) skew(0deg) translate(0px);
  right: 6px;
  top: -2px;
}
.chosen-container .chosen-drop {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-color: #e5e5e5;
}
.chosen-container .chosen-drop ul.chosen-results {
  max-height: 180px;
  padding: 0;
  margin: 0;
}
.chosen-container .chosen-drop ul.chosen-results li {
  position: relative;
  padding: 0 10px;
  line-height: 30px;
  color: #1D1D1D;
  font-size: 15px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding-right: 20px;
}
.chosen-container .chosen-drop ul.chosen-results li:after {
  content: "\f00c";
  position: absolute;
  display: block;
  right: 2px;
  top: 0;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  width: 18px;
  height: 18px;
  color: #1D1D1D;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-size: 17px;
}
.chosen-container .chosen-drop ul.chosen-results li.result-selected,
.chosen-container .chosen-drop ul.chosen-results li.result-selected.highlighted {
  background-color: #c6c6c6;
  background-image: none;
}
.chosen-container .chosen-drop ul.chosen-results li.result-selected:after,
.chosen-container .chosen-drop ul.chosen-results li.result-selected.highlighted:after {
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.chosen-container .chosen-drop ul.chosen-results li.highlighted {
  background-color: #e5e5e5;
  background-image: none;
}
.modal .close {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 24px;
  line-height: 24px;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  width: 24px;
  height: 24px;
  color: rgba(255, 255, 255, 0.7);
  text-shadow: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.modal .close:hover {
  color: #ffffff;
}
.page-light .modal .close,
.page-light .modal .close:focus {
  color: rgba(29, 29, 29, 0.7);
}
.page-light .modal .close:hover {
  color: #ff484e;
}
.modal-dialog {
  min-width: 320px;
  max-width: 550px;
  padding: 60px;
  background-color: #191919;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
}
.page-light .modal-dialog {
  background-color: #ffffff;
}
.modal_text {
  margin-bottom: 10px;
}
.modal_prim {
  margin-bottom: 20px;
}
.modal .js_title_in_modal {
  text-transform: lowercase;
}
.modal-open {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.form_row {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
.form_row:last-child {
  margin-bottom: 0;
}
.form_row .form-control {
  display: block !important;
  width: 100%;
}
.form_row .btn,
.form_row .prim {
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: middle;
}
.form_row .btn {
  margin-right: 20px;
}
.form_cols {
  word-spacing: -0.36em;
  display: table;
  width: 100%;
}
.form_cols_two .col1,
.form_cols_two .col2 {
  width: 48.5%;
}
.form_cols_three .col1 {
  width: 48.5%;
}
.form_cols_three .col2,
.form_cols_three .col3 {
  width: 22.7%;
}
.form_col {
  word-spacing: normal;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
  margin-right: 3%;
}
.form_col:last-child {
  margin-right: 0;
}
.wrap-promo-img {
  margin-bottom: 15px;
}
.promo-img {
  display: block;
}
.promo-text {
  margin-bottom: 15px;
}
.promo-policy {
  margin-top: 10px;
}
.spa-table {
  word-spacing: -0.36em;
  display: table;
  width: 100%;
  margin-bottom: 40px;
}
.spa-table:last-child {
  margin-bottom: 0;
}
.spa-table__head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 0;
  margin-bottom: 10px;
  font-size: 13px;
  text-align: left;
}
.spa-table__row-title {
  word-spacing: normal;
  display: block;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 20px;
}
.spa-table__row {
  margin-bottom: 12px;
}
.spa-table__col {
  word-spacing: normal;
  display: inline-block;
  vertical-align: top;
  line-height: 21px;
  padding-right: 10px;
}
.spa-table__col .spa-table__first {
  width: 41.66666667%;
}
.spa-table__col .spa-table__second {
  width: 33.33333333%;
}
.spa-table__col .spa-table__third {
  width: 25%;
}
.spa-table__first {
  width: 66.66666667%;
}
.spa-table__second {
  width: 16.66666667%;
}
.spa-table__third {
  width: 16.66666667%;
  text-align: right;
}
.spa-table.spa-table_rent .spa-table__first {
  width: 50%;
}
.spa-table.spa-table_rent .spa-table__second {
  width: 25%;
}
.spa-table.spa-table_rent .spa-table__third {
  width: 25%;
}
.spa-table.spa-table_hall .spa-table__first {
  width: 66.66666667%;
}
.spa-table.spa-table_hall .spa-table__second {
  width: 25%;
}
.spa-table.spa-table_hall .spa-table__third {
  width: 8.33333333%;
}
@media (max-width: 767px) {
  .spa-table__col {
    width: 100%;
    text-align: left;
  }
  .spa-table__second,
  .spa-table__third {
    padding-left: 30px;
  }
  .spa-table.spa-table_rent .spa-table__col {
    width: 100% !important;
    text-align: left;
  }
  .spa-table.spa-table_rent .spa-table__second,
  .spa-table.spa-table_rent .spa-table__third {
    padding-left: 30px;
  }
}
.grid_inline.home {
  margin-bottom: 80px;
}
.grid_inline.home .grid_inline_block {
  margin-bottom: 50px;
}
.grid_inline_title {
  margin-bottom: 40px;
}
.grid_inline_block {
  word-spacing: -0.36em;
  display: table;
  width: 100%;
}
.grid_inline_block.fw .grid_inline_block_one .content {
  padding-top: 145px;
}
.grid_inline_block.fw .grid_inline_block_one .content.content_rooms {
  padding-top: 100px;
}
.grid_inline_block.fw .grid_inline_block_one .link {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.grid_inline_block.fw .grid_inline_block_one .text {
  margin-top: 10px;
}
.grid_inline_block.fw .grid_inline_block_one.reserve .content.content_rooms {
  padding-top: 178px;
}
.grid_inline_block.fw .grid_inline_block_one.reserve .link {
  border-bottom: none;
}
.grid_inline_block_one {
  position: relative;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
  word-spacing: normal;
  width: 31.3%;
  margin: 0 3% 30px 0;
  min-height: 200px;
}
.grid_inline_block_one.three .grid_inline_block_one .content {
  padding-top: 100px;
}
.grid_inline_block_one.four {
  min-height: 170px;
  width: 22.7%;
  margin: 0 3% 30px 0;
}
.grid_inline_block_one.four .img {
  height: 170px;
}
.grid_inline_block_one.reserve .reserve_block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(29, 29, 29, 0.8);
}
.grid_inline_block_one.reserve .reserve_block_text {
  width: 165px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  font-family: Candara, Calibri, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: bold;
}
.grid_inline_block_one .action_img {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  max-width: 110px;
}
.grid_inline_block_one .action_img_center {
  right: 0;
  margin: 0 auto;
}
.grid_inline_block_one .img {
  position: absolute;
  top: 0;
  left: 0;
  height: 200px;
  overflow: hidden;
  width: 100%;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=80);
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.8;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.grid_inline_block_one .img img {
  display: block;
  margin: auto;
  width: auto;
  height: 100%;
}
.page-light .grid_inline_block_one .img .bottom_gradient {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
}
.grid_inline_block_one .content {
  display: block;
  position: relative;
  padding: 120px 15px 0;
}
.grid_inline_block_one .content .date {
  font-size: 13px;
  line-height: 19px;
  font-weight: 100;
  margin-bottom: 4px;
  color: #ffffff;
}
.page-light .grid_inline_block_one .content .date {
  color: #1D1D1D;
  font-weight: 300;
}
.grid_inline_block_one .content .link {
  font-family: Candara, Calibri, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.grid_inline_block_one .content .text {
  color: rgba(255, 255, 255, 0.9);
}
.page-light .grid_inline_block_one .content .text {
  color: rgba(25, 25, 25, 0.9);
}
.grid_inline_block_one .content .price_block {
  margin-top: 10px;
}
.grid_inline_block_one .content .price_block span {
  border-bottom: none;
}
.grid_inline_block_one .content .price_block .cost {
  color: #ffab16;
  font-size: 24px;
  margin-right: 3px;
}
.grid_inline_block_one .content .price_block .cost_action {
  color: #ff5343;
  text-decoration: line-through;
  margin-right: 15px;
}
.grid_inline_block_one .btn {
  margin-top: 20px;
}
.home_slider {
  height: 100%;
  width: 100%;
  background-color: #1D1D1D;
  min-height: 605px;
  z-index: 2;
  overflow: hidden;
  visibility: hidden;
  cursor: move;
  color: #ffffff;
}
.home_slider_one {
  padding-top: 110px;
  padding-bottom: 120px;
  width: 100%;
  height: 100%;
  position: relative;
}
.home_slider_one > .bg_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=80);
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.8;
}
.home_slider_one_text {
  position: relative;
  max-width: 645px;
}
.home_slider_one_text .text {
  margin-bottom: 30px;
}
.home_slider_one_text .btn {
  margin-right: 15px;
  margin-bottom: 10px;
}
.home_slider_one_thumb {
  width: 200px;
  height: 95px;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 16px 16px -10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 16px 16px -10px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 16px 16px -10px rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.home_slider_one_thumb:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;
}
.home_slider_one_thumb .bg_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=60);
  -moz-opacity: 0.6;
  -khtml-opacity: 0.6;
  opacity: 0.6;
  margin-top: 5px;
}
.home_slider_one_thumb_text {
  position: relative;
  z-index: 1;
}
.home_slider .rsThumbs {
  position: relative;
  top: -135px;
  display: block;
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  font-weight: normal;
  float: none;
  padding: 20px 20px;
}
.home_slider .rsThumbs .rsThumb.rsNavSelected .slide_timer {
  visibility: visible;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.home_slider .rsThumbs .rsThumb.rsNavSelected .home_slider_one_thumb {
  -webkit-box-shadow: 0px 30px 25px -16px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 30px 25px -16px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 30px 25px -16px rgba(0, 0, 0, 0.5);
  cursor: default;
}
.home_slider .rsThumbs .rsThumb.rsNavSelected .home_slider_one_thumb:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.page-light .home_slider .bottom_gradient {
  opacity: .5;
}
.royalSlider > .rsContent {
  visibility: hidden;
  display: none;
}
.slide_timer {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 5px;
  background-color: rgba(255, 171, 22, 0.3);
  visibility: hidden;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.slide_timer_time {
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(255, 171, 22, 0.7);
  height: 100%;
  width: 100%;
  z-index: 5;
}
.advan.home {
  padding-top: 80px;
  margin-bottom: 30px;
}
.advan_block {
  word-spacing: -0.36em;
  display: table;
  width: 100%;
}
.advan_block.four {
  margin-bottom: 80px;
}
.advan_block_one {
  word-spacing: normal;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
  width: 20%;
  text-align: center;
  margin-bottom: 25px;
  padding: 0 10px;
}
.advan_block_one.five {
  margin-bottom: 35px;
}
.advan_block_one.six {
  width: 16.66%;
}
.advan_block_one.seven {
  width: 13.66%;
}
.advan_block_one.five {
  width: 20%;
}
.advan_block_one.five .icon {
  margin-bottom: 15px;
}
.advan_block_one.five .icon img {
  max-width: 50px;
  max-height: 50px;
}
.advan_block_one.four {
  width: 25%;
  margin-bottom: 0;
}
.advan_block_one.four:nth-child(1),
.advan_block_one.four:nth-child(2),
.advan_block_one.four:nth-child(3) {
  width: 33.3%;
  margin-bottom: 40px;
}
.advan_block_one.four:nth-child(1) .icon,
.advan_block_one.four:nth-child(2) .icon,
.advan_block_one.four:nth-child(3) .icon {
  margin-bottom: 25px;
  height: 100px;
}
.advan_block_one.four:nth-child(1) .icon img,
.advan_block_one.four:nth-child(2) .icon img,
.advan_block_one.four:nth-child(3) .icon img {
  max-width: 130px;
  max-height: 100px;
}
.advan_block_one.four:nth-child(1) .text a,
.advan_block_one.four:nth-child(2) .text a,
.advan_block_one.four:nth-child(3) .text a {
  font-size: 24px;
  line-height: 30px;
}
.advan_block_one.four .icon {
  margin-bottom: 15px;
  height: 58px;
}
.advan_block_one.four .icon img {
  max-width: 58px;
  max-height: 58px;
}
.advan_block_one.four .text a {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}
.advan_block_one .icon {
  margin-bottom: 30px;
}
.advan_block_one .icon img {
  display: block;
  margin: 0 auto;
  max-width: 46px;
  max-height: 46px;
  max-height: 100%;
}
.info_img_block {
  position: relative;
  padding: 60px 0;
  text-align: center;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
}
.info_img_block.min {
  padding: 35px 0;
}
.info_img_block.serv .text {
  margin-bottom: 20px;
}
.info_img_block_content {
  max-width: 770px;
  margin: 0 auto;
}
.info_img_block .text {
  margin-bottom: 20px;
  max-width: 770px;
}
.info_img_block_prim {
  margin-bottom: 20px;
  font-weight: 100;
  font-style: italic;
}
.subscribe_sucsess_close {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 24px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.subscribe_sucsess_close:hover {
  color: #ffffff;
}
.reserve_home_block {
  position: absolute;
  top: 110px;
  right: -25px;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
  z-index: 9;
}
.reserve_home_block .btn {
  margin-top: 30px;
}
.reserve_home_block .page_tabs {
  margin-bottom: 10px;
}
.spinner {
  margin: 200px auto 200px;
  width: 70px;
  text-align: center;
}
.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.wifi {
  display: block;
  width: 100%;
  height: 100%;
  background: transparent center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: center;
}
.wifi__tb {
  background-color: rgba(25, 25, 25, 0.6);
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}
.wifi__cell {
  display: table-cell;
  vertical-align: middle;
}
.wifi__logo {
  display: block;
  width: 200px;
  margin: 0 auto 20px;
}
.wifi__logo-img {
  display: block;
  width: 100%;
}
.wifi__actions .btn {
  margin-right: 10px;
  margin-bottom: 10px;
}
.wifi__actions:last-child {
  margin-right: 0;
}
.info-block {
  color: #3d3d3d;
  padding: 35px 25px;
  background-image: linear-gradient(rgba(240, 163, 10, 0.12), rgba(240, 163, 10, 0.12)), linear-gradient(#fff, #fff);
  margin-bottom: 20px;
  text-align: center;
}
.info-block__text {
  margin-bottom: 25px;
}
.info-block__text span {
  display: block;
  font-size: 20px;
  color: #f0a30a;
  font-weight: 700;
  margin-bottom: 15px;
}
.info-block__text p {
  margin-bottom: 5px;
}
.info-block__text p:last-child {
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .info-block {
    padding: 20px 10px;
  }
  .info-block__text {
    margin-bottom: 10px;
  }
  .info-block__text span {
    margin-bottom: 10px;
  }
}
.home_map {
  position: relative;
  padding: 80px 0 60px;
  margin-bottom: 30px;
  -webkit-box-shadow: inset 0 0 100px 30px #1D1D1D;
  -moz-box-shadow: inset 0 0 100px 30px #1D1D1D;
  box-shadow: inset 0 0 100px 30px #1D1D1D;
}
.home_map .text {
  margin-bottom: 35px;
}
.home_map_init {
  display: block;
  overflow: hidden;
  width: 100%;
  max-width: 425px;
  margin: 0 auto;
  max-height: 235px;
  -webkit-box-shadow: 0px 30px 25px -16px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 30px 25px -16px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 30px 25px -16px rgba(0, 0, 0, 0.5);
}
.home_map_init:hover {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=70);
  -moz-opacity: 0.7;
  -khtml-opacity: 0.7;
  opacity: 0.7;
}
.home_map_init img {
  display: block;
  max-height: 100%;
  max-width: 100%;
}
.news {
  margin-bottom: 80px;
}
.news.view {
  margin-bottom: 0;
}
.news_block_one {
  position: relative;
  padding-left: 200px;
  min-height: 110px;
  margin-bottom: 50px;
}
.news_block_one .image {
  position: absolute;
  left: 0;
  top: 0;
  width: 170px;
  height: 110px;
  overflow: hidden;
}
.news_block_one .image img {
  display: block;
  max-width: 100%;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=90);
  -moz-opacity: 0.9;
  -khtml-opacity: 0.9;
  opacity: 0.9;
}
.news_block_one .date {
  margin-bottom: 5px;
  width: auto !important;
}
.news_block_one .title h3 {
  margin-bottom: 10px;
}
.gal {
  padding: 0 20px;
}
.gal_grid:after {
  content: "";
  display: block;
  clear: both;
}
.gal_grid_one {
  position: relative;
  display: block;
  float: left;
  padding: 20px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -khtml-border-radius: 3px;
  overflow: hidden;
  width: 30.7%;
  height: 230px;
  margin-right: 2.6%;
  margin-bottom: 30px;
}
.gal_grid_one,
.gal_grid_one:focus {
  color: #ffffff !important;
}
.gal_grid_one:hover {
  color: #ff484e !important;
}
.gal_grid_one .bg_fade {
  background-color: rgba(29, 29, 29, 0.3);
}
.gal_grid_one .counter {
  position: absolute;
  top: 20px;
  left: 20px;
  min-width: 46px;
  height: 36px;
  line-height: 30px;
  text-align: center;
  padding: 0 5px;
  font-weight: 700;
  font-size: 20px;
  color: #1D1D1D;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  -khtml-border-radius: 3px;
  background: transparent url(/images/icons/gal_counter.png) center no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}
.gal_grid_one .title {
  position: absolute;
  bottom: 20px;
  left: 0;
  padding: 0 20px;
}
.gal_grid_one .title h1,
.gal_grid_one .title h2,
.gal_grid_one .title h3,
.gal_grid_one .title h4,
.gal_grid_one .title h5,
.gal_grid_one .title h6 {
  margin-bottom: 0;
}
.gal_grid_one .title .date {
  margin-bottom: 5px;
}
.gal_view .date {
  margin-bottom: 10px;
}
.gal_view .page_content {
  margin-bottom: 0;
}
.gal_slider {
  visibility: hidden;
  height: 570px;
  margin-bottom: 135px;
  overflow: visible;
}
.gal_slider.photos {
  background-color: transparent;
}
.gal_slider.tours {
  margin-bottom: 114px;
  height: 1px;
}
.gal_slider.tours .gal_slider_one_thumb_text {
  text-align: left;
}
.gal_slider.tours .rsThumbs {
  bottom: -114px;
}
.gal_slider.tours .rsThumbs .rsThumb .gal_slider_one_thumb_text {
  visibility: visible;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.gal_slider.min {
  height: 300px;
  margin-bottom: 80px;
}
.gal_slider.min .gal_slider_one_thumb {
  height: 55px;
  width: 85px;
}
.gal_slider.min .rsThumbs {
  bottom: -85px;
}
.gal_slider_one {
  width: 100%;
  height: 100%;
}
.gal_slider_one .bottom_gradient {
  height: 60px;
}
.gal_slider_one .rsImg {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.gal_slider_one_text {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px;
  z-index: 2;
}
.gal_slider_one_thumb {
  width: 200px;
  height: 90px;
  padding: 10px 20px;
  -webkit-box-shadow: 0px 16px 16px -10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 16px 16px -10px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 16px 16px -10px rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.gal_slider_one_thumb .bg_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.gal_slider_one_thumb_text {
  position: relative;
  z-index: 1;
  visibility: hidden;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.gal_slider .rsThumbs {
  position: absolute;
  bottom: -120px;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  font-weight: normal;
  float: none;
  padding: 5px 0 20px;
  text-align: center;
}
.gal_slider .rsThumbs.bordered:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 87.5%;
  background-color: #ffab16;
  z-index: 3;
}
.gal_slider .rsThumbs .rsThumbsContainer {
  min-width: 99%;
}
.gal_slider .rsThumbs .rsThumb {
  float: none;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.gal_slider .rsThumbs .rsThumb:after {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #ffab16;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  visibility: hidden;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}
.gal_slider .rsThumbs .rsThumb.rsNavSelected:after {
  visibility: visible;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.gal_slider .rsThumbs .rsThumb.rsNavSelected .gal_slider_one_thumb_text {
  visibility: visible;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.gal_slider .rsThumbs .rsThumb.rsNavSelected .gal_slider_one_thumb {
  -webkit-box-shadow: 0px 30px 25px -16px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 30px 25px -16px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 30px 25px -16px rgba(0, 0, 0, 0.5);
}
.gal_slider .rsThumbs .rsThumb.rsNavSelected .rsImg {
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=50);
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;
}
.page-light .gal_slider .bottom_gradient {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
}
iframe.gal_slider {
  visibility: visible;
}
.video .page_content {
  margin-bottom: 0;
}
.video_block_one {
  margin-bottom: 60px;
}
.video_block_one:last-child {
  margin-bottom: 0;
}
.video_iframe {
  height: 350px;
  width: 100%;
}
.services_line {
  margin-bottom: 30px;
}
.services_line:last-child {
  margin-bottom: 0;
}
.services_line .wrapper_content {
  padding: 0 20px;
}
.services_block {
  word-spacing: -0.36em;
  display: table;
  width: 100%;
  margin-bottom: 30px;
}
.services_block_one {
  word-spacing: normal;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
  width: 48.5%;
  margin-right: 3%;
  overflow: hidden;
}
.services_block_one:last-child {
  margin-right: 0;
}
.services_block_one .info_img_block {
  height: 100%;
}
.restaurant_form {
  margin-top: -10px;
  margin-bottom: 35px;
}
.restaurant_middle_check {
  text-align: center;
  margin-bottom: 60px;
}
.restaurant_middle_check:after {
  content: "";
  display: block;
  clear: both;
}
.restaurant_middle_check_one {
  position: relative;
  overflow: hidden;
  float: left;
  padding: 10px;
  width: 26.5%;
  margin-right: 10.2%;
}
.restaurant_middle_check_one:last-child {
  margin-right: 0;
}
.restaurant_middle_check_one .price {
  color: #ffab16;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 5px;
}
.restaurant_middle_check_one .img {
  width: 100%;
  height: 145px;
  margin-bottom: 20px;
  margin-top: 30px;
}
.restaurant_middle_check_one .text {
  margin-bottom: 0;
  height: 40px;
}
.restaurant_title {
  margin-bottom: 40px;
}
.restaurant_grid {
  word-spacing: -0.36em;
  display: table;
  width: 100%;
}
.restaurant_grid_one {
  word-spacing: normal;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
  width: 21.4%;
  margin-right: 4.8%;
  margin-bottom: 40px;
}
.restaurant_grid_one:last-child {
  margin-bottom: 60px;
}
.restaurant_grid_one .img {
  width: 100%;
  height: 165px;
  margin-bottom: 20px;
}
.pool .title_page {
  padding-top: 0;
  margin-bottom: 30px;
}
.pool_descrip {
  margin-bottom: 30px;
}
.pool_tabs {
  margin-top: 0;
  margin-bottom: 0;
}
.pool_tabs.top {
  margin-bottom: 20px;
  padding-top: 50px;
}
.pool_tabs_content {
  margin-bottom: 30px;
}
.pool_tabs_content img {
  margin: 0 auto;
  max-height: 570px;
}
.pool_price {
  font-size: 32px;
  line-height: 38px;
  font-weight: 500;
  margin-bottom: 10px;
}
.pool_price .prim {
  font-size: 15px;
}
.pool_form {
  text-align: center;
  /* .form-group{
			margin-bottom: 10px;
			&:last-child{
				margin-bottom: 0;
			}

		} */
}
.pool_form_text_left {
  text-align: left;
}
.pool_form_inline .pool_price,
.pool_form_inline .btn {
  display: inline-block;
  vertical-align: middle;
}
.pool_form_inline .pool_price {
  margin-right: 20px;
}
.pool_form .btn {
  margin-top: 10px;
}
.excursions_descrip {
  margin-bottom: 40px;
}
.excursions_menu {
  max-width: 265px;
  width: 100%;
  text-align: center;
}
.excursions_menu_block li {
  margin: 0 0 15px;
  display: inline-block;
  width: 100%;
  max-width: 265px;
  overflow: hidden;
}
.excursions_menu_block li a {
  position: relative;
  display: block;
  border-radius: 3px;
  padding: 0;
}
.excursions_menu_block li a:after {
  display: none !important;
}
.excursions_menu_block li a .img {
  height: 170px;
  overflow: hidden;
}
.excursions_menu_block li a img {
  display: block;
  width: 100%;
  height: auto;
}
.excursions_menu_block li a span {
  display: block;
  margin-top: 10px;
  text-align: center;
  width: 100%;
  border-bottom: none;
}
.excursions_menu_block li a span:first-child {
  margin-top: 0;
}
.excursions_menu_block li.active a {
  cursor: default;
  background-color: #d6941e;
}
.excursions_menu_block li.active a:after {
  content: "";
  position: absolute;
  top: 0;
  left: -5px;
  height: 100%;
  width: 5px;
  background-color: #ffab16;
  display: block;
}
.excursions_menu_block li.active a,
.excursions_menu_block li.active a:focus {
  color: #ffffff;
  padding: 5px 20px;
}
.excursions_menu_title {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 24px;
}
.excursions_title_block {
  margin-bottom: 20px;
}
.excursions_title_block h2.js_excurs_title {
  text-align: center;
}
.exc_slider .rsNav.rsThumbs.rsThumbsHor,
.exc_slider .bottom_gradient {
  display: none;
}
.exc_slider,
.exc_slider img,
.exc_slider .rsContent.gal_slider_one,
.exc_slider .rsOverflow.grab-cursor {
  height: 450px !important;
}
.exc_slider img {
  display: block;
  margin: 0 auto !important;
  width: auto !important;
}
.reviews_block_one {
  margin-bottom: 40px;
}
.reviews_block_one_name .name {
  font-weight: 300;
  margin: 5px 0;
}
.reviews_block_one .rating-container .star {
  margin: 0 12px 0 0;
}
.reviews_block_one .rating-container .empty-stars,
.reviews_block_one .rating-container .filled-stars {
  color: #ffab16;
}
.reviews_btns {
  margin-bottom: 60px;
}
.reviews_form_block_btns {
  margin-bottom: 40px;
}
.reviews_form_block_btns .btn {
  margin-right: 30px;
}
.about_descrip {
  margin-bottom: 40px;
}
.about_text {
  margin-bottom: 80px;
}
.about .title_line:after,
.about .title_line:before {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.about .title_line.about_personal_title {
  text-align: left;
}
.about_reviews .reviews_block_one_name {
  margin-bottom: 10px;
}
.about_reviews .reviews_block_one_name .name {
  width: 50%;
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}
.about_reviews .reviews_block_one_name .rating-container {
  text-align: right;
  float: right;
  width: 50%;
  line-height: 30px;
  margin: 5px 0;
}
.about_reviews_btns a {
  margin-right: 30px;
}
.about_reviews_btns a:last-child {
  margin-right: 0;
}
.about_block {
  margin-bottom: 80px;
}
.about_questions .how_move {
  margin-bottom: 40px;
}
.about_questions .questions {
  margin: 0;
}
.contacts .title_page {
  position: relative;
  margin-bottom: 25px;
  padding-bottom: 25px;
}
.contacts .title_page:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 80px;
  height: 1px;
  background-color: #ffab16;
  margin-left: -40px;
}
.contacts_descrip {
  margin-bottom: 40px;
}
.contacts_list {
  word-spacing: -0.36em;
  display: table;
  width: 100%;
  max-width: 770px;
  margin: 0 auto 40px;
}
.contacts_list li {
  position: relative;
  word-spacing: normal;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
  padding-left: 61px;
  width: 33.33%;
  min-height: 46px;
}
.contacts_list li .icon {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 46px;
  height: 46px;
}
.contacts_list li .worktime {
  margin-bottom: 10px;
}
.contacts_list li .phone {
  font-size: 18px;
  line-height: 24px;
}
.contacts .questions {
  margin-bottom: 0;
}
#map {
  width: 100%;
  height: 390px;
  margin-bottom: 70px;
}
.transfer .contacts_list {
  margin-bottom: 60px;
}
.cars_param_text {
  margin-bottom: 20px;
}
.cars_param_list {
  word-spacing: -0.36em;
  display: table;
  width: 100%;
}
.cars_param_list li {
  word-spacing: normal;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
  margin-right: 30px;
  margin-bottom: 20px;
}
.cars_param_list li:last-child {
  margin-right: 0;
}
.cars_param_list li .icon_block {
  height: 30px;
  margin-bottom: 10px;
}
.cars_param_list li .icon_block .icon {
  display: block;
  height: 100%;
  max-width: 40px;
}
.cars_carusel {
  display: block;
  float: none;
  width: 100%;
  margin-bottom: 30px;
  overflow: hidden;
}
.cars_carusel_one_thumb {
  width: 135px;
  text-align: center;
}
.cars_carusel_one_thumb .icon {
  display: block;
  max-width: 100%;
  margin-bottom: 10px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.cars_carusel_one_thumb .title {
  border-bottom: 1px dotted rgba(255, 171, 22, 0.4);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: #ffffff;
}
.cars_carusel_one_thumb .active {
  display: block;
  height: 5px;
  width: 100%;
  margin-top: 10px;
  visibility: hidden;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  background-color: #ffab16;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.cars_carusel .rsOverflow {
  display: none;
}
.cars_carusel .rsThumbs {
  float: none;
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.cars_carusel .rsThumbs .rsThumbsContainer {
  min-width: 99%;
}
.cars_carusel .rsThumbs .rsThumb {
  float: none;
  display: inline-block;
  zoom: 1;
  *display: inline;
  vertical-align: top;
}
.cars_carusel .rsThumbs .rsThumb.rsNavSelected .active,
.cars_carusel .rsThumbs .rsThumb.rsNavSelected:hover .active {
  visibility: visible;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
.cars_carusel .rsThumbs .rsThumb.rsNavSelected .title,
.cars_carusel .rsThumbs .rsThumb.rsNavSelected:hover .title {
  color: #ffffff;
  border-bottom-color: transparent;
}
.cars_carusel .rsThumbs .rsThumb:hover .title {
  color: #ff484e;
  border-bottom-color: rgba(255, 72, 78, 0.4);
}
.rooms_form {
  margin-bottom: 50px;
}
.rooms_count_block {
  text-align: center;
  margin-bottom: 6px;
  color: #ffffff;
}
.rooms_count_block .count {
  font-size: 48px;
  line-height: 51px;
  border: none;
}
.rooms_count_block .count_text {
  border: none;
}
.rooms_count_block span {
  border-bottom: none;
}
.rooms_view .title_line {
  margin-bottom: 40px;
}
.rooms_view .gal_tabs {
  margin-bottom: 0;
}
.rooms_view_text {
  padding-top: 20px;
  margin-bottom: 60px;
}
.rooms_view_text.action {
  margin-bottom: 20px;
}
.rooms_view_text_action {
  margin-bottom: 60px;
}
.rooms_view_advan {
  margin-bottom: 25px;
}
.rooms_view_reviews {
  margin-bottom: 40px;
}
.rooms_view_reserve {
  margin-bottom: 80px;
  background-color: #a47d37;
  padding: 10px 0;
}
.rooms_view_reserve .rooms_form_view {
  margin-bottom: 0;
}
.rooms_view_reserve_price_block {
  padding-top: 20px;
}
.rooms_view_reserve_price_block .cost {
  font-size: 32px;
  line-height: 38px;
  font-weight: 500;
}
.rooms_view_reserve_price_block .cost_action {
  text-decoration: line-through;
}
.rooms_view_reserve_price_block .prim {
  font-size: 15px;
}
.rooms_view_reserve.action {
  background-color: #a4373a;
}
.rooms_view_reserve.action .rooms_view_reserve_price_block {
  padding-top: 0;
}
@media (max-width: 767px) {
  body {
    padding-left: 0;
  }
  .wrapper_content {
    padding: 0;
  }
  .wrapper_content.view {
    padding: 80px 0 0;
    max-width: 100%;
  }
  .wrapper_content.main {
    padding: 0;
  }
  .wrapper_content.main.gal {
    padding: 0;
  }
  .wrapper_content.main.gal_view {
    padding-top: 80px;
  }
  .content {
    padding-bottom: 60px;
  }
  .h1,
  h1,
  .h2,
  h2,
  .h3,
  h3 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .h4,
  h4 {
    font-size: 18px;
    line-height: 24px;
  }
  .form-inline .form-group {
    margin-right: 0;
  }
  .btn {
    padding: 11px 20px;
  }
  .btn.big {
    padding: 11px 20px;
  }
  .btn_min,
  .btn_min:focus {
    padding: 6px 20px 8px;
    font-size: 15px;
    line-height: 16px;
  }
  .text_bigger,
  .text_bigger p,
  .text_bigger ul,
  .text_bigger ol,
  .text_bigger li,
  .text_bigger div {
    font-size: 15px;
    line-height: 21px;
  }
  .title_line {
    text-align: center;
  }
  .title_line:before,
  .title_line:after {
    display: none;
  }
  .title_line span.right {
    font-size: 13px;
    float: none;
  }
  .title_page {
    padding-top: 90px;
    font-size: 30px;
    line-height: 34px;
  }
  .title_page.view {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .page_img img {
    height: 133px;
  }
  .page_content {
    margin-bottom: 40px;
  }
  .page_bg {
    width: 100%;
    min-height: 370px;
    margin-bottom: 30px;
  }
  .page_bg_text {
    margin-bottom: 30px;
  }
  .page_bg_img {
    height: 370px;
  }
  .page_bg .wrapper_content {
    padding: 0 20px;
  }
  .page_tabs {
    margin-bottom: 30px;
  }
  .nav_main {
    padding-top: 60px;
  }
  .home_slider {
    min-height: 300px;
  }
  .home_slider_one {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 80px;
  }
  .home_slider_one_text .text {
    margin-bottom: 20px;
  }
  .home_slider_one_thumb {
    width: 120px;
    height: 55px;
    padding: 10px 10px;
  }
  .home_slider_one_thumb_text {
    font-size: 15px;
    line-height: 21px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .home_slider .rsThumbs {
    top: -95px;
  }
  .questions {
    margin: 50px 0;
  }
  .advan.home {
    padding-top: 40px;
  }
  .advan.home .title_line {
    margin-bottom: 40px;
  }
  .advan_block_one {
    display: block;
    width: 150px;
    margin: 0 auto 30px;
  }
  .advan_block_one.six {
    width: 150px;
  }
  .advan_block_one.five {
    width: 100%;
  }
  .advan_block_one.four {
    width: 100%;
    margin-bottom: 50px;
  }
  .advan_block_one.four:nth-child(1),
  .advan_block_one.four:nth-child(2),
  .advan_block_one.four:nth-child(3) {
    width: 100%;
  }
  .advan_block_one.four:nth-child(1) .text a,
  .advan_block_one.four:nth-child(2) .text a,
  .advan_block_one.four:nth-child(3) .text a {
    font-size: 18px;
    line-height: 24px;
  }
  .advan_block_one .icon {
    margin-bottom: 30px;
  }
  .home_map {
    padding: 20px 0 0;
    text-align: center;
    margin-bottom: 20px;
  }
  .home_map .btns {
    margin-bottom: 30px;
  }
  .info_img_block {
    padding: 20px;
  }
  .grid_inline.home {
    margin-bottom: 40px;
  }
  .grid_inline_title {
    margin-bottom: 10px;
  }
  .grid_inline_block {
    margin: 0 auto 0;
  }
  .grid_inline_block_one {
    display: block;
    width: 100%;
    margin-bottom: 50px;
  }
  .grid_inline_block_one.four {
    width: 100%;
    margin: 0 auto 30px auto;
  }
  .news_block_one {
    padding-left: 0;
    margin-bottom: 30px;
  }
  .news_block_one .image {
    position: relative;
    height: auto;
    width: 100%;
    margin-bottom: 15px;
    max-height: 190px;
  }
  .news_block_one .image img {
    width: 100%;
    margin: auto;
  }
  .gal_grid_one {
    width: 100%;
    height: 170px;
    margin-right: 0;
  }
  .gal_slider {
    height: 185px;
    margin-bottom: 80px;
  }
  .gal_slider.tours {
    margin-bottom: 69px;
  }
  .gal_slider.tours .rsThumbs {
    bottom: -69px;
  }
  .gal_slider_one_thumb {
    height: 45px;
    width: 95px;
  }
  .gal_slider .rsThumbs {
    bottom: -80px;
  }
  .gal_slider .rsThumbs.bordered:after {
    height: 73.5%;
  }
  .video_block_one {
    margin-bottom: 30px;
  }
  .services .wrapper_content {
    padding: 0 20px;
  }
  .services_block {
    margin-bottom: 0;
  }
  .services_block_one {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
  }
  .restaurant_form {
    margin: 0 auto 60px;
  }
  .restaurant_form .inline {
    display: inline-block;
    zoom: 1;
    *display: inline;
    vertical-align: top;
    width: 47%;
    margin-right: 2%;
    margin-bottom: 0;
  }
  .restaurant_form .inline:last-child {
    margin-right: 0;
  }
  .restaurant_middle_check {
    margin-bottom: 60px;
  }
  .restaurant_middle_check_one {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .restaurant_middle_check_one:last-child {
    margin-bottom: 0;
  }
  .restaurant_grid_one {
    width: 100%;
    margin-right: 0;
  }
  .restaurant_title {
    margin-bottom: 20px;
  }
  .restaurant .title_line {
    text-align: left;
  }
  .restaurant .title_line:before,
  .restaurant .title_line:after {
    display: inline-block;
    zoom: 1;
    *display: inline;
  }
  .pool_tabs.top {
    padding-top: 80px;
  }
  .pool_text {
    margin-bottom: 20px;
  }
  .pool_form {
    max-width: 210px;
    margin: 0 auto;
  }
  .excursions_content {
    padding-left: 20px;
  }
  .form_subscibe {
    max-width: 290px;
  }
  .form_subscibe .btn {
    display: block;
    width: 100%;
  }
  .reviews_block_one {
    margin-bottom: 30px;
  }
  .reviews_block_one_name {
    margin-bottom: 15px;
  }
  .reviews_btns {
    margin-bottom: 40px;
  }
  .reviews_btns .btn {
    display: block;
    width: 100%;
  }
  .feedback_form .form-group.left,
  .feedback_form .form-group.right {
    float: none;
    width: 100%;
    margin-bottom: 30px;
  }
  .feedback_form_btn {
    margin-right: 0;
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  .feedback_form_btn + .prim {
    display: block;
    width: 100%;
    text-align: center;
  }
  .about_reviews {
    margin-bottom: 40px;
  }
  .about_reviews .reviews_block_one_name {
    margin-bottom: 10px;
  }
  .about_reviews .reviews_block_one_name .name {
    width: 50%;
    float: left;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
  }
  .about_reviews .reviews_block_one_name .rating-container {
    line-height: 24px;
    margin: 5px 0;
  }
  .about_block {
    margin-bottom: 60px;
  }
  .contacts_list {
    max-width: 250px;
  }
  .contacts_list li {
    width: 100%;
    margin-bottom: 20px;
  }
  .contacts_list li:last-child {
    margin-bottom: 0;
  }
  .feedback_form .files_block .btn_block .btn,
  .feedback_form .files_block .btn_block .prim {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
  #map,
  .home_map_init {
    height: 245px;
    margin-bottom: 60px;
  }
  .reviews_block_one {
    margin-bottom: 30px;
  }
  .reviews_form_block_btns {
    position: relative;
    margin-bottom: 20px;
    padding-top: 40px;
  }
  .reviews_form_block_btns .btn {
    display: block;
    width: 100%;
  }
  .reviews_form_block_btns .link {
    display: block;
    top: -40px;
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
  }
  .rooms_block_one {
    margin-bottom: 30px;
  }
  .rooms_view .title_line {
    text-align: left;
  }
  .rooms_view .title_line:after,
  .rooms_view .title_line:before {
    display: inline-block;
    zoom: 1;
    *display: inline;
  }
  .rooms_view_text {
    padding-top: 0;
    max-width: 570px;
    margin: 0 auto 30px;
  }
  .rooms_view_text.action {
    margin-bottom: 20px;
  }
  .rooms_view_text_action {
    margin-bottom: 30px;
  }
  .rooms_view_advan {
    max-width: 100%;
    margin-bottom: 5px;
  }
  .rooms_view_reviews {
    margin-bottom: 40px;
  }
  .modal-dialog {
    margin: 0;
    padding: 40px 20px;
    max-width: 100%;
    width: 100%;
    min-height: 100%;
  }
  .modal.fade .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transition: -webkit-transform 0.3s ease-out;
    -o-transition: -o-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
  }
  .modal-open {
    position: fixed;
  }
  .form_row {
    margin-bottom: 20px;
  }
  .form_row:last-child {
    margin-bottom: 0;
  }
  .form_row .form-control {
    display: block !important;
    width: 100%;
  }
  .form_row .btn,
  .form_row .prim {
    display: block;
  }
  .form_row .btn {
    margin-right: 0;
    margin-bottom: 15px;
    width: 100%;
  }
  .form_cols_two .col1,
  .form_cols_two .col2 {
    width: 100%;
  }
  .form_cols_two .col1 {
    margin-bottom: 20px;
  }
  .form_cols_three .col1 {
    width: 100%;
    margin-bottom: 20px;
  }
  .form_cols_three .col2,
  .form_cols_three .col3 {
    width: 48.5%;
  }
  .form_col {
    margin-right: 3%;
  }
  .empty {
    height: 930px;
  }
  .footer {
    margin-top: -930px;
    height: 930px;
  }
  .footer .wrapper {
    padding-top: 40px;
  }
  .footer_pre_nav_menu,
  .footer_pre_nav_trip {
    margin-bottom: 40px;
  }
  .footer_pre_nav_trip {
    text-align: center;
  }
  .footer_pre_nav_trip .icon {
    margin: 0 auto 10px;
  }
  .footer_pre_nav_menu_grid_item {
    padding-right: 0;
    text-align: center;
    width: 100%;
  }
  .footer_pre_btns {
    text-align: center;
    margin-bottom: 40px;
  }
  .footer_pre_btns .left,
  .footer_pre_btns .right {
    float: none;
  }
  .footer_pre_btns .left {
    margin-bottom: 30px;
  }
  .footer_pre_btns a {
    vertical-align: top;
    margin-right: 10px;
    margin-bottom: 20px;
  }
  .footer_pre_btns a:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
  .footer_block {
    text-align: center;
  }
  .footer_block .left,
  .footer_block .right {
    float: none;
  }
  .footer_block .left {
    margin-bottom: 30px;
  }
  .footer_block .prim,
  .footer_block .link {
    display: block;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .footer_block .line {
    margin-top: 0;
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  .home_slider_one {
    padding-top: 70px;
  }
  .home_slider .rsThumbs {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  body {
    padding-left: 0;
  }
  .wrapper_content {
    max-width: 650px;
    padding: 0;
  }
  .wrapper_content.view {
    padding: 80px 0 0;
    max-width: 100%;
  }
  .wrapper_content.main.gal {
    padding: 0;
  }
  .page_descrip {
    max-width: 570px;
  }
  .page_img img {
    height: 345px;
  }
  .page_content {
    margin-bottom: 50px;
  }
  .page_content p,
  .page_content ul,
  .page_content ol,
  .page_content blockquote,
  .page_content h1,
  .page_content h2,
  .page_content h3,
  .page_content h4,
  .page_content h5,
  .page_content h6 {
    max-width: 570px;
  }
  .nav_main {
    padding-top: 60px;
  }
  .title_line {
    text-align: center;
  }
  .title_line span.right {
    float: none;
  }
  .title_page {
    padding-top: 85px;
    font-size: 42px;
    line-height: 48px;
  }
  .title_page.view {
    font-size: 36px;
    line-height: 42px;
  }
  .advan.home {
    padding-top: 40px;
  }
  .advan_block {
    max-width: 570px;
    margin: 0 auto;
  }
  .advan_block.four {
    margin-bottom: 10px;
  }
  .advan_block_one {
    width: 20%;
    margin-bottom: 30px;
  }
  .advan_block_one.six {
    width: 33.33%;
  }
  .advan_block_one.four {
    width: 50%;
    margin-bottom: 50px;
  }
  .advan_block_one.four:nth-child(1) {
    width: 100%;
    max-width: 420px;
  }
  .advan_block_one.four:nth-child(2),
  .advan_block_one.four:nth-child(3) {
    width: 50%;
  }
  .home_map {
    padding: 30px 0 30px;
    text-align: center;
  }
  .home_map .btns {
    margin-bottom: 40px;
  }
  .info_img_block {
    padding: 40px 0;
  }
  .grid_inline.home {
    margin-bottom: 60px;
  }
  .grid_inline_title {
    margin-bottom: 30px;
  }
  .grid_inline_block {
    max-width: 570px;
    margin: 0 auto 0 !important;
  }
  .grid_inline_block.fw {
    max-width: 100%;
  }
  .grid_inline_block_one {
    width: 48.5%;
    margin-bottom: 50px;
  }
  .grid_inline_block_one.three:nth-child(2n) {
    margin-right: 0;
  }
  .grid_inline_block_one.four {
    width: 48.5%;
  }
  .grid_inline_block_one.four:nth-child(2n) {
    margin-right: 0;
  }
  .gal_grid_one {
    width: 48%;
    height: 225px;
    margin-right: 4%;
  }
  .gal_grid_one:nth-child(2n) {
    margin-right: 0;
  }
  .gal_slider {
    height: 355px;
    margin-bottom: 90px;
  }
  .gal_slider.tours {
    margin-bottom: 84px;
  }
  .gal_slider.tours .rsThumbs {
    bottom: -84px;
  }
  .gal_slider_one_thumb {
    height: 60px;
    width: 120px;
  }
  .gal_slider .rsThumbs {
    bottom: -90px;
  }
  .gal_slider .rsThumbs.bordered:after {
    height: 78%;
  }
  .video_block_one {
    margin-bottom: 40px;
  }
  .info_img_block.min {
    padding: 20px;
  }
  .info_img_block.min .info_img_block_title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
  }
  .restaurant_form {
    margin-bottom: 60px;
  }
  .restaurant_middle_check_one {
    width: 32%;
    margin-right: 2%;
  }
  .restaurant_grid_one {
    width: 30.1%;
  }
  .restaurant_grid_one:nth-child(3n) {
    margin-right: 0;
  }
  .restaurant .title_line {
    text-align: left;
  }
  .restaurant .title_line:before,
  .restaurant .title_line:after {
    display: inline-block;
    zoom: 1;
    *display: inline;
  }
  .pool_tabs.top {
    padding-top: 80px;
  }
  .excursions_form {
    max-width: 570px;
    margin: 0 auto;
  }
  .about_reviews {
    margin-bottom: 60px;
  }
  .about_block .how_move {
    margin-bottom: 0;
  }
  .modal-open {
    position: fixed;
  }
  #map,
  .home_map_init {
    height: 245px;
    margin-bottom: 60px;
  }
  .reviews_block_one {
    margin-bottom: 30px;
  }
  .reviews_form_block_btns {
    margin-bottom: 20px;
  }
  .rooms_view.wrapper_content {
    padding: 0;
    max-width: 100%;
  }
  .rooms_view .title_line {
    text-align: left;
  }
  .rooms_view_text {
    padding-top: 0;
    max-width: 570px;
    margin: 0 auto 40px;
  }
  .rooms_view_text.action {
    margin-bottom: 20px;
  }
  .rooms_view_text_action {
    margin-bottom: 40px;
  }
  .rooms_view_advan {
    max-width: 100%;
    margin-bottom: 25px;
  }
  .rooms_view_reviews {
    margin-bottom: 40px;
  }
  .empty {
    height: 530px;
  }
  .footer {
    margin-top: -530px;
    height: 530px;
  }
  .footer_pre_nav_menu,
  .footer_pre_nav_trip {
    margin-bottom: 40px;
  }
  .footer_pre_btns {
    text-align: center;
    margin-bottom: 50px;
  }
  .footer_pre_btns .left,
  .footer_pre_btns .right {
    float: none;
  }
  .footer_pre_btns .left {
    margin-bottom: 20px;
  }
  .footer_pre_btns a {
    margin-right: 10px;
  }
  .footer_pre_btns a:last-child {
    margin-right: 0;
  }
  .footer_block {
    text-align: center;
  }
  .footer_block .left,
  .footer_block .right {
    float: none;
  }
  .footer_block .left {
    margin-bottom: 20px;
  }
  .footer_block .prim,
  .footer_block .link {
    margin-right: 20px;
  }
}
@media (max-width: 1199px) {
  .excursions_menu {
    max-width: 100%;
    margin-top: 40px;
  }
  .excursions_menu_block li {
    max-width: 265px;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .wrapper_content.main.gal {
    padding: 0 20px;
  }
  .breadcrumb_block {
    margin-top: -20px;
  }
  .nav_main {
    -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  .home_map {
    padding: 30px 0 30px;
    text-align: center;
  }
  .home_map .btns {
    margin-bottom: 40px;
  }
  .advan_block_one.four {
    width: 50%;
    margin-bottom: 50px;
  }
  .advan_block_one.four:nth-child(1) {
    width: 100%;
    max-width: 420px;
  }
  .advan_block_one.four:nth-child(2),
  .advan_block_one.four:nth-child(3) {
    width: 50%;
  }
  .grid_inline.home {
    margin-bottom: 60px;
  }
  .grid_inline_title {
    margin-bottom: 30px;
  }
  .grid_inline_block {
    max-width: 570px;
    margin: 0 auto 0!important;
  }
  .grid_inline_block.fw {
    max-width: 100%;
  }
  .grid_inline_block_one {
    width: 48.5%;
    margin-bottom: 50px;
  }
  .grid_inline_block_one.four {
    width: 48.5%;
  }
  .grid_inline_block_one.four:nth-child(2n) {
    margin-right: 0;
  }
  .grid_inline_block_one.three:nth-child(2n) {
    margin-right: 0;
  }
  .gal_grid_one {
    width: 48%;
    height: 225px;
    margin-right: 4%;
  }
  .gal_grid_one:nth-child(2n) {
    margin-right: 0;
  }
  .restaurant_form {
    margin-bottom: 60px;
  }
  .restaurant_middle_check_one {
    width: 32%;
    margin-right: 2%;
  }
  .restaurant_grid_one {
    width: 30.1%;
  }
  .restaurant_grid_one:nth-child(3n) {
    margin-right: 0;
  }
  .about_reviews {
    margin-bottom: 60px;
  }
  .about_block .how_move {
    margin-bottom: 0;
  }
  .empty {
    height: 530px;
  }
  .footer {
    margin-top: -530px;
    height: 530px;
  }
  .footer_pre_nav_line {
    margin-bottom: 50px;
  }
  .footer_pre_btns {
    text-align: center;
    margin-bottom: 50px;
  }
  .footer_pre_btns .left,
  .footer_pre_btns .right {
    float: none;
  }
  .footer_pre_btns .left {
    margin-bottom: 20px;
  }
  .footer_pre_btns a {
    margin-right: 10px;
  }
  .footer_pre_btns a:last-child {
    margin-right: 0;
  }
  .footer_block {
    text-align: center;
  }
  .footer_block .left,
  .footer_block .right {
    float: none;
  }
  .footer_block .left {
    margin-bottom: 20px;
  }
  .footer_block .prim,
  .footer_block .link {
    margin-right: 20px;
  }
}
@media (min-width: 1200px) {
  .nav_main {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.8);
  }
  .page-light .nav_main {
    -webkit-box-shadow: 0 0 50px rgba(29, 29, 29, 0.16);
    -moz-box-shadow: 0 0 50px rgba(29, 29, 29, 0.16);
    box-shadow: 0 0 50px rgba(29, 29, 29, 0.16);
  }
  .breadcrumb_block {
    margin-top: -20px;
  }
  .news_block_one {
    padding-left: 300px;
  }
  .news_block_one .image {
    left: 100px;
  }
  .news_block_one:first-child {
    min-height: 175px;
  }
  .news_block_one:first-child .image {
    width: 270px;
    height: 175px;
    left: 0;
  }
  .news_block_one:first-child .text,
  .news_block_one:first-child .text p {
    font-size: 18px;
    line-height: 24px;
  }
  .grid_inline_block_one.three {
    margin-bottom: 50px;
  }
  .grid_inline_block_one.three:nth-child(3n) {
    margin-right: 0;
  }
  .grid_inline_block_one.four {
    margin-bottom: 0;
  }
  .grid_inline_block_one.four:nth-child(4n) {
    margin-right: 0;
  }
  .info_img_block.big .info_img_block_title {
    font-size: 48px;
    line-height: 54px;
  }
  .gal_grid {
    margin-right: -1.3%;
    margin-left: -1.3%;
  }
  .gal_grid_one {
    margin-right: 1.3%;
    margin-left: 1.3%;
    /*&:nth-child(1), &:nth-child(2),
				&:nth-child(3), &:nth-child(4){
					margin-left: 0;
				}*/
  }
  .gal_grid_one:nth-child(1) {
    width: 63.4%;
    height: 490px;
    margin-right: 2.7%;
    margin-bottom: 30px;
  }
  .gal_grid_one:nth-child(1),
  .gal_grid_one:nth-child(4) {
    margin-left: 1.3%;
  }
  .gal_grid_one:nth-child(2),
  .gal_grid_one:nth-child(3),
  .gal_grid_one:nth-child(5) {
    margin-right: 1.3%;
    margin-left: 0;
  }
  .gal_grid_one:nth-child(1) h4,
  .gal_grid_one:nth-child(4) h4,
  .gal_grid_one:nth-child(5) h4 {
    font-size: 36px;
    line-height: 42px;
  }
  .gal_grid_one:nth-child(2),
  .gal_grid_one:nth-child(3) {
    width: 31.3%;
    height: 230px;
    margin-bottom: 30px;
  }
  .gal_grid_one:nth-child(4),
  .gal_grid_one:nth-child(5) {
    width: 47.3%;
    height: 355px;
  }
  .gal_grid_one:nth-child(4) {
    margin-right: 1.35%;
  }
  .gal_grid_one:nth-child(5) {
    margin-left: 1.35%;
    margin-right: 1.3%;
    margin-bottom: 40px;
  }
  .restaurant_grid_one:nth-child(4n) {
    margin-right: 0;
  }
  .video_iframe {
    height: 500px;
    padding: 0 100px;
  }
}
/*Zakharium*/
.main-psk-phone {
  font-size: 18px;
}
/*menu*/
.nav_main_contacts .social a {
  font-size: 22px;
  line-height: 1;
}
.nav_main_contacts .social a:last-child {
  margin-right: 0;
}
.nav_main_contacts .social a.booking_img {
  display: none;
}
.nav_main_contacts .social a.mambara {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url(../../images/icons/mambara-hover.png);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  position: relative;
  margin-bottom: -2px;
}
.nav_main_contacts .social a.mambara:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(../../images/icons/mambara.png);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  opacity: 1;
  -webkit-transition: opacity .2s ease;
  -o-transition: opacity .2s ease;
  transition: opacity .2s ease;
}
.nav_main_contacts .social a.mambara:hover:after {
  opacity: 0;
}
/*footer*/
.footer_block .left a:nth-child(4) {
  display: none;
}
.footer_block .left a:last-child {
  margin-right: 0;
}
.footer_block.clearfix .right {
  float: right;
}
/*offers*/
.special-offer-page .page_tabs,
.special-offer-page .tab-content {
  display: none;
}
.special-offer-page .offer-pictupe-text {
  width: 100%;
  overflow: hidden;
  padding-bottom: 50px;
  border-bottom: 1px solid #343434;
  margin-top: 30px;
}
.special-offer-page .offer-pictupe-text img {
  display: block;
  width: 58.3333333%;
  height: auto;
  float: left;
}
.special-offer-page .offer-pictupe-text .offer-pictupe-text-right {
  width: 41.6666666%;
  float: left;
  padding-left: 20px;
  font-size: 18px;
  color: #DCDCDC;
}
.special-offer-page .offer-pictupe-text .offer-pictupe-text-right ul li {
  list-style-type: disc;
}
.special-offer-page .offer-pictupe-text .offer-pictupe-text-right .text-center {
  text-align: left;
  margin-top: 30px;
}
.special-offer-page hr {
  border-color: #343434;
}
/*excursions*/
.page_content.excursions_content.wow.fadeInUp hr {
  border-color: #343434;
}
.page_content.excursions_content.wow.fadeInUp p strong {
  font-size: 16px;
}
/*GALLERY*/
/*MAIN*/
li.grid_inline_block_one.four.wow.fadeInUp.list-chess {
  display: block;
  width: 100%;
  height: 300px;
}
li.grid_inline_block_one.four.wow.fadeInUp.list-chess .img.bg_img {
  display: inline-block;
  height: 230px;
  width: 100%;
  padding: 0;
  -webkit-background-size: contain;
  background-size: contain;
  background-position: 0 0;
}
li.grid_inline_block_one.four.wow.fadeInUp.list-chess .date {
  display: inline-block;
}
li.grid_inline_block_one.four.wow.fadeInUp.list-chess a {
  width: 49%;
  padding: 0;
  text-align: right;
}
li.grid_inline_block_one.four.wow.fadeInUp.list-chess:nth-child(2n+1) a {
  float: right;
  text-align: left;
}
li.grid_inline_block_one.four.wow.fadeInUp.list-chess:nth-child(2n) .action_img {
  right: 0;
  left: auto;
}
li.grid_inline_block_one.four.wow.fadeInUp.list-chess:nth-child(2n) .img.bg_img {
  background-position: right 0;
}
/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .exc_slider,
  .exc_slider img,
  .exc_slider .rsContent.gal_slider_one,
  .exc_slider .rsOverflow.grab-cursor {
    height: 400px !important;
  }
}
@media (max-width: 767px) {
  /*footer*/
  .footer_block.clearfix .right {
    float: none;
  }
  .advan.home .advan_block {
    margin-bottom: 30px;
  }
  .advan.home .advan_block li.advan_block_one.six {
    display: block;
    width: 100%;
    max-width: 400px;
    min-height: 50px;
    padding: 15px 0;
    border-top: 1px solid #343434;
    margin-bottom: 0;
  }
  .advan.home .advan_block li.advan_block_one.six a {
    display: table;
    width: 100%;
    height: 100%;
  }
  .advan.home .advan_block li.advan_block_one.six:first-child {
    border-top: 0;
  }
  .advan.home .advan_block li.advan_block_one.six .icon {
    display: inline-block;
    float: left;
    margin-bottom: 0;
  }
  .advan.home .advan_block li.advan_block_one.six .text {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
  }
  .advan.home .advan_block li.advan_block_one.six .text p {
    padding: 0 11px;
    width: 100%;
    max-width: 250px;
    text-align: center;
    display: inline-block;
  }
  /*offers*/
  .special-offer-page .offer-pictupe-text img {
    width: 100%;
    margin-bottom: 20px;
  }
  .special-offer-page .offer-pictupe-text .offer-pictupe-text-right {
    width: 100%;
    padding-left: 0;
  }
  .special-offer-page .offer-pictupe-text .offer-pictupe-text-right p {
    font-size: 15px;
  }
  .special-offer-page .offer-pictupe-text .offer-pictupe-text-right .text-center {
    text-align: center;
  }
  /*excursions*/
}
.booking-button-main {
  display: none;
}
#tl-search-form {
  margin-bottom: 30px;
  padding: 22px;
  border: 2px solid #ffbd49;
  border-radius: 4px;
  background: #363636;
}
@media (max-width: 344px) {
  #tl-search-form {
    padding: 22px 0 22px 10px;
  }
}
.tl-search-form-main {
  width: 303px;
  float: right;
  background: rgba(0, 0, 0, 0.7) !important;
}
@media (max-width: 1294px) and (min-height: 765px) {
  .reserve_home_block {
    top: 332px;
    right: 2px;
  }
  .reserve_home_block .tl-search-form-main {
    padding: 0px 22px 13px;
  }
}
@media (max-width: 767px) and (max-height: 765px) {
  .reserve_home_block {
    top: 290px;
    right: 0;
    left: 0;
  }
  .reserve_home_block .tl-search-form-main {
    display: block;
  }
  .reserve_home_block .booking-button-main {
    display: none;
  }
}
@media (max-width: 1294px) and (min-height: 805px) {
  .reserve_home_block .tl-search-form-main {
    padding: 22px;
  }
}
@media (max-width: 425px) and (min-height: 765px) {
  .reserve_home_block .tl-search-form-main {
    float: none;
    margin: 0 auto;
  }
}
@media (max-width: 1294px) and (max-height: 765px) {
  .reserve_home_block {
    top: -174px;
    right: 0;
  }
  .reserve_home_block .tl-search-form-main {
    display: none;
  }
  .reserve_home_block .booking-button-main {
    display: block;
    text-align: center;
    margin: 0 auto;
    max-width: 290px;
  }
}
@media (max-width: 767px) and (max-height: 765px) {
  .reserve_home_block {
    top: -127px;
    right: 0;
  }
}
